@media (prefers-color-scheme: dark) {
    .project-item {
      --list-item-color: #1a1a1a;
      --list-item-color-hover: #333;
    }
  }
  
  @media (prefers-color-scheme: light) {
    .project-item {
        --list-item-color: #f9f9f9;
        --list-item-color-hover: #F5f5f5;
    }
  }

.projects-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    flex: 1;
    gap: 1rem;
}

.project-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    border-radius: 1.5rem;
    padding: 1rem;
    width: calc(50% - 2.5rem);
    text-decoration: none;
    transition: all ease-out 200ms;
    background-color: var(--list-item-color);
}

.project-item:hover {
    background-color: var(--list-item-color-hover);
}

.project-item:hover img {
    scale: 1.05;
    border-radius: .7rem;
}

.project-item li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
}

.project-item img {
    height: 6rem;
    width: 6rem;
    border-radius: .5rem;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    object-fit: cover;
    transition: all ease-out 200ms;
}

.project {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 3rem;
    line-height: 150%;
}

.project img {
    display: flex;
    margin: 1rem -3rem;
    width: calc(100% + 6rem);
    border-radius: 2rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    transition: all ease-out 250ms;
}

.project img:hover {
    scale: 1.01;
    border-radius: 2.5rem;
}

.image-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1rem -2rem;
    transition: all ease-out 250ms;
}

.image-grid:hover {
    scale: 1.02;
}

.image-grid:hover img {
    border-radius: 1.25rem;
}

.image-grid img {
    display: flex;
    flex: 3;
    margin: 0;
    width: calc(50% - 2rem);
    height: 300px;
    border-radius: 1rem;
    object-fit: cover;
    transition: all ease-out 250ms;
}

.image-caption {
    font-size: smaller;
    text-align: center;
}

.project section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.project li {
    margin-bottom: 1rem;
}

.project blockquote {
    padding: 1em;
    font-size: 1.125rem;
}

.video-wrapper {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.project video {
    border-radius: 1rem;
    max-width: 100%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    transition: all ease-out 200ms;
}

.project video:hover {
    scale: 1.01;
    border-radius: 1.25rem;
}


@media screen and (max-width: 600px) {
    .project-item {
        width: 100%;
    }

    .project {
        margin: 0;
    }

    .project img {
        margin: 0;
        width: 100%
    }

    .project img:hover {
        scale:1.0;
        border-radius: 2rem;
    }

    .image-grid {
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 1rem 0;
        width: 100%;
    }

    .image-grid:hover {
        scale:  1.0;
    }

    .image-grid img {
        width: 100%;
        margin: 0
    }

    .video-wrapper {
        flex-direction: column;
    }

    .project video {
        max-height: 450px;
    }

    .project video:hover {
        scale: 1.0;
        border-radius: 1rem;
    }
}