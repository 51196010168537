@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #000;
    --text-color: #f5f5f5;
    --interpunct-color: #555;
    --hr-color: #7a7a7a6d;
    --skill-text-color: #bfbfbf;
    --skill-text-highlight-color: #f5f5f5;
    --skill-bg-color: #1c1c1c;
    --skill-bg-highlight-color: #373737;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --bg-color: #FFF;
    --text-color: #000;
    --interpunct-color: rgba(0, 0, 0, 0.25);
    --hr-color: #55555555;
  
    --skill-text-color: #0a0909;
    --skill-text-highlight-color: #000;

    --skill-bg-color: #F5F5F5;
    --skill-bg-highlight-color: #E1E1E1;
  }
}

* {
  margin: 0;
  padding: 0;
}

html {
  scrollbar-gutter: stable both-edges;
}

body {
  color: var(--text-color);
  background: var(--bg-color);
}

div, p, a, h3, h4, h5, h6 {
  font-family: 'IBM Plex Sans', sans-serif;
}

h1, h2, h3 {
  font-family: 'IBM Plex Mono', serif;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

a {
  color:var(--text-color);
}

a:hover {
  text-decoration: none;
}

.hidden {
  display: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
  align-items: flex-start;
  max-width: 50rem;
  width: calc(100% - 2rem);
  margin: 4rem 1rem;
  gap: 4rem;
}

hr {
  border: 0.5px solid var(--hr-color);
  width: 100%;
}

/* Header */
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.avatar {
  height: 110px;
  width: 110px;
  border-radius: 20%;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.15);
  transition: all ease-out 250ms;
}

.avatar:hover {
  border-radius: 25%;
  scale: 1.05;
}

/* Nav */
.nav ul {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  font-size: 1.5em;
  list-style: none;
}


.nav a {
  color: var(--text-color);
  opacity: 0.5;
  text-decoration: none;
  font-family: IBM Plex Mono;
}

.nav a:hover {
  color: var(--text-color);
  opacity: 1;
  text-decoration: underline;
}

.nav .active {
  color: var(--text-color);
  opacity: 1;
}

/* Contact */
.contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-left: -0.6rem;
  margin-top: 0.5rem;
}

.contact a {
  display: flex;
  width: fit-content;
  padding: 0.35rem 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: var(--skill-text-color);
  transition: all ease-out 200ms;
}

.contact a:hover {
  color: var(--skill-text-highlight-color);
  background-color: var(--skill-bg-color);
  scale: 1.015;
}

/* Bio */
.bio {
  width: 66%;
  font-size: 1.35rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}

.bio a {
  font-family: 'IBM Plex Serif', serif;
}

/* About */
.about {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  gap: 1.5rem;
  padding: 0rem 3rem;
  font-size: 1.125em;
  font-style: normal;
  line-height: 2em;
}

/* Resume */
.resume {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4rem;
}

.positions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.position {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.position-header {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.complex-position {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.company {
  font-weight: 600;
}

.interpunct {
  display: flex;
  flex: 1;
  border: 1px dashed var(--interpunct-color);
}

.spacer {
  width: 1.5rem;
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.skills-group {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
}

.skill {
  display: flex;
  padding: 0.35rem 0.5rem;
  align-items: flex-start;
  gap: 0.25rem;

  border-radius: 0.5rem;
  background-color: var(--skill-bg-color);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.10);
  
  color: var(--skill-text-color);
  font-family: IBM Plex Mono;
  font-size: 1rem;

  transition: all ease-out 200ms;
}

.skill:hover {
  color: var(--skill-text-highlight-color);
  cursor: crosshair;
  scale: 1.015;
  border-radius: 0.6rem;
  background-color: var(--skill-bg-highlight-color);
}

/* Projects */
.projects {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 3rem;
}

.projects section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.projects h4 {
  margin-top: 1em;
}

.project ul {
  margin-left: 1rem;
}

.roles-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;

}

/* Footer */
footer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

footer :first-child {
  display: flex;
  flex: 1;  
  align-self: flex-start;
}

@media screen and (max-width: 600px) {
  .position-header {
    flex-direction: column;
    gap: 0;
  }

  .about, .projects {
    padding: 0;
  }

  .app-header img {
    height: 80px;
    width: 80px;
  }

  .nav ul {
    font-size: 1.2em;
    gap: 2rem;
  }
}

@media screen and (max-width: 425px) {
  
}
